exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-buy-tsx": () => import("./../../../src/pages/buy.tsx" /* webpackChunkName: "component---src-pages-buy-tsx" */),
  "component---src-pages-cocktails-tsx": () => import("./../../../src/pages/cocktails.tsx" /* webpackChunkName: "component---src-pages-cocktails-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-our-essence-tsx": () => import("./../../../src/pages/our-essence.tsx" /* webpackChunkName: "component---src-pages-our-essence-tsx" */),
  "component---src-pages-our-tequila-tsx": () => import("./../../../src/pages/our-tequila.tsx" /* webpackChunkName: "component---src-pages-our-tequila-tsx" */),
  "component---src-pages-privacy-policies-tsx": () => import("./../../../src/pages/privacy-policies.tsx" /* webpackChunkName: "component---src-pages-privacy-policies-tsx" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */)
}

